
import { Component, Mixins } from 'vue-property-decorator'
import { Step } from '@/mixins/StepMixin'
import RadioGroup from '@/components/RadioGroup.vue'

@Component({
  components: {
    RadioGroup
  }
})
export default class ChooseSkill extends Mixins(Step) {
  options = this.$store.state.currentPage.selectOptions.map((option: string) => this.$t(option))
  choice = ''

  groupName = 'skill'

  beforeMount (): void {
    if (this.$store.state.userData.skill.choice) {
      this.choice = this.$store.state.userData.skill.choice
      this.enableNextNavigation()
    }
  }

  updateSelected (option: string) {
    this.enableNextNavigation()
    this.$store.dispatch('setUserData', {
      skill: { choice: option }
    })
  }
}
