
import { Vue, Component, Prop } from 'vue-property-decorator'
import { MvIcon } from '@monosolutions/vue-components'

@Component({
  components: {
    MvIcon
  }
})
export default class RadioGroup extends Vue {
  @Prop() options: any
  @Prop({ default: 'radio' }) readonly groupName!: string
  @Prop() optionSelected: any

  selected = ''

  get actualValue () {
    if (this.selected === '' && this.optionSelected) {
      return this.optionSelected
    } else if (this.selected !== '') {
      return this.selected
    } else {
      return this.optionSelected
    }
  }

  onChange (event: any): void {
    this.selected = event.target.value
    this.$emit('selected', this.selected)
  }
}
